.refund-policy-container {
  background-color: #26263E;
  color: #ffffff;
  min-height: 100vh;
  padding-bottom: 2rem;
}

.refund-policy-header {
  background-color: #1E1E30;
  padding: 2rem 0;
  text-align: center;
  margin-bottom: 2rem;
}

.refund-policy-title {
  font-size: 2.5rem;
  font-weight: bold;
}

.refund-policy-content {
  background-color: #2E2E4A;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.refund-policy-content h2 {
  color: #FFD700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.refund-policy-content p,
.refund-policy-content li {
  line-height: 1.6;
}

.refund-policy-content a {
  color: #00BFFF;
  text-decoration: none;
}

.refund-policy-content a:hover {
  text-decoration: underline;
}

.refund-policy-content ul,
.refund-policy-content ol {
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .refund-policy-title {
    font-size: 2rem;
  }

  .refund-policy-content {
    padding: 1.5rem;
  }
}
