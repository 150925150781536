/* Styles for all screen sizes */
.generator-container {
  background-color: #26263E;
  width: 100%;
}

.generator-header {
  text-align: center;
  color: white;
  padding: 20px 0;
  background-color: #26263E;
}

.generator-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.generator-description {
  font-size: 1rem;
  max-width: 600px;
  margin: 0 auto 20px;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  .generator-header {
    display: none !important; /* Use !important to override any conflicting styles */
  }
  
  /* Remove any potential spacing that might be left */
  .generator-container {
    padding-top: 0;
    margin-top: 0;
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) {
  .generator-header {
    display: block;
  }

  .generate-column {
    padding-left: 130px !important; /* Use !important to override any inline styles */
  }
}

.generate-column {
  background-color: #26263E;
  padding: 0px;
  border-radius: 10px;
}

/* Desktop styles */
@media (min-width: 992px) {
  .generate-column {
    padding-left: 130px;
  }
}
