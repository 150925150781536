.subscription-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f4f6;
  font-family: Arial, sans-serif;
}

.subscription-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-description {
  color: #6b7280;
  margin-bottom: 1.5rem;
}

.price {
  margin-bottom: 1.5rem;
}

.amount {
  font-size: 2.5rem;
  font-weight: bold;
}

.period {
  font-size: 1rem;
}

.features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

.features li {
  margin-bottom: 0.5rem;
}

.subscribe-button {
  display: inline-block;
  background-color: #8a2be2; /* Purple color */
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px; /* Fully rounded corners */
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #7a1dd1; /* Slightly darker purple on hover */
}
