.generate-container {
  background-color: #181833;
  border-radius: 10px;
  padding: 20px;
}

.art-style-scroll {
  display: flex;
  overflow-y: auto;
  padding: 10px 0;
}

.art-style-item {
  display: inline-block;
  width: 100px;
  text-align: center;
}

.art-style-scroll::-webkit-scrollbar {
  height: 12px;
}

.art-style-scroll::-webkit-scrollbar-track {
  background: #555;
  border-radius: 10px;
}

.art-style-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.app {
  background-color: #1a0933;
  color: white;
  padding: 20px;
  font-family: Arial, sans-serif;
  min-height: 50vh;
}

.options-bar,
.styles-bar,
.aspect-ratio-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

button {
  background-color: #2c1052;
  border: none;
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

button.selected {
  background-color: rgb(205, 147, 255);
}


.models-grid {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  margin-bottom: 10px;
  -webkit-overflow-scrolling: touch; /* for smooth scrolling on iOS */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.models-grid::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.model-item {
  flex: 0 0 auto;
  width: 100px; /* Adjust this value as needed */
  padding: 10px;
  position: relative;
  background-color: #2c1052;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.model-item img {
  width: 100%;
  height: 100px; /* Make this the same as the width for square images */
  object-fit: cover;
  border-radius: 5px;
}

.model-item span {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crown-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
}

/* Desktop styles */
@media (min-width: 769px) {
  .models-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    overflow-x: auto;
    max-height: calc(3 * (100px + 20px + 17px)); /* 4 rows: image height + padding + text */
  }
}

/* Mobile styles remain unchanged */
@media (max-width: 768px) {
  .models-grid, .control-button-ratio {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .models-grid, .control-button-ratio {
    grid-template-columns: repeat(2, 1fr);
  }
}

.selected {
  background-color: rgb(205, 147, 255);
  border-radius: 8px;
}

.top-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.control-button {
  
  padding: 5px 10px;
  background-color: #2c1052;
  cursor: pointer;
}

.control-button-ratio {
  padding: 1px 5px;
 
  /* gap: 10px; */
  /* border: 1px solid #f2eeee; */
  border-radius: 4px;
  margin-right: 1rem;
  background-color: #2c1052;
  cursor: pointer;
  /* margin-top: 0.5rem; */
  margin-bottom: 1rem;
}

.control-button.selected,
.control-button-ratio.selected {
  background-color: rgb(205, 147, 255);
  border-color: rgb(244, 243, 244);
}

.prompt-input {
  width: 100%;
  background-color: #2c1052;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 15px 5px;
  margin-bottom: 15px;
  resize: none;
  height: 100px;
}

.create-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-image: url('/public/button.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.loading {
  text-align: center;
  margin-top: 20px;
}