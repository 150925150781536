.signin-modal-body {
  display: flex;
  padding: 0;
}

.signin-container {
  display: flex;
  width: 100%;
}

.signin-left {
  flex: 1;
  padding: 15px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signin-right {
  flex: 1;
  padding: 20px;
  background-image: url('../../public/background.png');
  background-size: cover;
  background-position: center;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.signin-right .text-container {
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 20px;
  border-radius: 10px;
}

.signin-right .text-container h1,
.signin-right .text-container p {
  color: white; /* Change to white for better readability */
  margin: 0;
  font-size: 1.2em; /* Increase font size as needed */
  font-weight: bold; /* Optional: Increase font weight */
}

/* Adjustments for responsiveness */
@media (max-width: 768px) {
  .signin-right .text-container h1,
  .signin-right .text-container p {
    font-size: 1em; /* Adjust text size for smaller screens */
  }
}

@media (max-width: 480px) {
  .signin-right .text-container h1,
  .signin-right .text-container p {
    font-size: 0.9em; /* Further adjust text size for smaller screens */
  }
}
.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  background-color: #FFF;
  border: 1px solid #DDD;
  border-radius: 5px;
  cursor: pointer;
}

.google-button img {
  width: 20px;
  margin-right: 10px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #AAA;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #AAA;
}

.divider::before {
  margin-right: 10px;
}

.divider::after {
  margin-left: 10px;
}

.input-group {
  position: relative;
  margin-bottom: 15px;
}
.invalid-password {
  border: 1px solid red;
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #f8f9fa;
  color: #090909;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.input-group:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.signin-input {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #DDD;
  border-radius: 5px;
}

.forgot-password {
  color: #6D28D9;
  text-decoration: none;
  margin-top: 10px;
}

.signin-button {
  width: 100%;
  padding: 10px;
  background-color: #6D28D9;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.signin-right h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.signin-right ul {
  list-style-type: none;
  padding: 0;
}

.signin-right ul li {
  margin-bottom: 10px;
}