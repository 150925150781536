.navbar {
    background-color: #181833;
    box-shadow: 0 2px 4px rgba(158, 155, 155, 0.1);
    padding: 0.5rem 1rem;
  }
  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
  .custom-toggler.navbar-toggler {
    border-color: rgba(255,255,255,0.9);
  }
  
  .custom-toggler:focus {
    box-shadow: 0 0 0 0.25rem rgba(255,255,255,0.25);
  }
  
  @font-face {
    font-family: 'New Amsterdam';
    src: url('../../public/NewAmsterdam-Regular.ttf') format('ttf'),
         url('../../public/NewAmsterdam-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
 
  
  .brand {
    display: flex;
    align-items: center;
    gap: 10px;

  }
  
  .logo {
    margin-right: 10px;
    width: 60px;
    height: 60px;
    border-radius:  4px; /* Adjust this value for more or less rounding */
  }
  
  .brandName {
    font-family: 'New Amsterdam', sans-serif !important;
    font-size: 24px !important;
    font-weight: bold !important;
    padding-left: 1rem !important;
   
  }
  
  .navLinks {
    margin-left: auto;
  }
  
  .navLink {
    color: #333;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
  }
  
  .navLink:hover {
    color: #007bff;
  }

  .white-nav-dropdown .dropdown-toggle {
    color: white !important;
  }
  
  /* Optional: Change color on hover */
  .white-nav-dropdown .dropdown-toggle:hover {
    color: #f0f0f0 !important;
  }
  
  /* Style for dropdown */
  :global(.dropdown-menu) {
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  :global(.dropdown-item) {
    color: #333;
    padding: 0.5rem 1rem;
  }
  
  :global(.dropdown-item:hover) {
    background-color: #181833;
    color: #007bff;
  }
  
  @media (max-width: 991px) {
    .navLinks {
      margin-top: 1rem;
    }
  }